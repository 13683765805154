import { render, staticRenderFns } from "./NotificationsList.vue?vue&type=template&id=57b8e812"
import script from "./NotificationsList.vue?vue&type=script&lang=ts"
export * from "./NotificationsList.vue?vue&type=script&lang=ts"
import style0 from "./NotificationsList.vue?vue&type=style&index=0&id=57b8e812&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports